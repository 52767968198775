import React from 'react'
import PropTypes from 'prop-types'
//import Img from "gatsby-image"

//import Image from './Image'
//import Content from './Content'
import './PageHeader.css'

const PageHeader = ({
  title,
  subtitle,
  large,
  className = ''
}) => {
  if (large) className += ' PageHeader-large'
  return (
    <div className={`PageHeader relative ${className}`}>
      <div className="relative">
        <h1 className="PageHeader--Title">{title}</h1>
        {subtitle && (
          <h3 className="PageHeader--Subtitle">{subtitle}</h3>
        )}
      </div>
    
    </div>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
